import InfoIconsCamera from '@/modules/device-module/components/header/InfoIconsCamera'
import {
  useGetDeviceDetailQuery,
  useGetPhotosDetailQuery,
} from '@/modules/device-module/services/deviceService'
import { Box } from '@mui/material'
import Paper from '@mui/material/Paper'
import * as React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import ActionMenu from './ActionMenu'
import InfoIcons from './InfoIcons'
import Title from './Title'
import WarningMessage from './WarningMessage'

const Header = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const { data } = useGetDeviceDetailQuery(Number(id), {
    skip: pathname.includes('photos'),
  })
  const { data: photosData } = useGetPhotosDetailQuery(Number(id), {
    skip: !pathname.includes('photos'),
  })

  return (
    <Paper
      elevation={6}
      style={{ backgroundColor: 'transparent' }}
      sx={{ boxShadow: 'unset', pb: { xss: 0, lg: 0 } }}
    >
      <Box
        display='flex'
        flexDirection='column'
        p={2}
        pt={{ xss: 0, lg: 1 }}
        pb={0}
      >
        <Box
          display='flex'
          justifyContent='space-between'
          flexWrap='wrap'
        >
          <Title />
          {data?.smart ? (
            <InfoIcons />
          ) : data?.typeId === 7 ||
            data?.typeId === 8 ||
            photosData?.typeId === 7 ? (
            <InfoIconsCamera />
          ) : null}
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          flexWrap='wrap'
          pt={{ xss: 0, lg: 0.5 }}
          pb={{ xss: 0, lg: 0.5 }}
        >
          <ActionMenu />
          {data?.typeId === 7 ||
          data?.typeId === 6 ||
          data?.typeId === 5 ||
          data?.typeId === 8 ||
          photosData?.typeId === 7 ? null : (
            <>{data?.state ? <WarningMessage /> : null}</>
          )}
        </Box>
      </Box>
    </Paper>
  )
}

export default Header
