import { useAppSelector } from '@/hooks'
import DownloadAction from '@/modules/device-module/components/header/DownloadAction'
import FavoritesMenu from '@/modules/device-module/components/header/FavoritesMenu'
// import { useGetDeviceDetailQuery }       from '@/modules/device-module/services/deviceService'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
// import { useGetUserProfileQuery }            from '@/modules/user-aqua/services/userService'
import PrintIcon from '@mui/icons-material/Print'
import { Box, CircularProgress, IconButton } from '@mui/material'
import axios from 'axios'
import dayjs from 'dayjs'
import printJS from 'print-js-updated'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useGetDeviceDetailQuery } from '@/modules/device-module/services/deviceService'

const ActionMenu = () => {
  // const { data: currentUserData } = useGetUserProfileQuery('0')
  // const currentUserType = currentUserData?.data.typeId

  const { id } = useParams()

  const { data } = useGetDeviceDetailQuery(Number(id))
  const isMeteoStation = data?.typeId === 4
  const { fromDate, toDate } = useAppSelector(stateDeviceSelector)

  const [isLoading, setIsLoading] = React.useState(false)

  const handlePrint = async () => {
    try {
      setIsLoading(true)
      const data = {
        deviceId: Number(id),
        dateFrom: dayjs(fromDate).toISOString(),
        dateTo: dayjs(toDate).toISOString(),
        formatTypeId: 2,
      }
      const res = await axios({
        url: `${import.meta.env.VITE_API_URL}/api/devices/reportIndividual`,
        method: 'POST',
        data: data,
        withCredentials: true,
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
        },
      })
      const reader = new FileReader()
      reader.readAsDataURL(res.data)
      reader.onloadend = function () {
        const base64data = reader.result
        const base64WithoutPrefix =
          base64data && typeof base64data === 'string'
            ? base64data.split(',')[1]
            : ''
        printJS({
          printable: base64WithoutPrefix,
          type: 'pdf',
          base64: true,
        })
      }
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      display='flex'
      alignItems='flex-start'
    >
      <FavoritesMenu />
      <DownloadAction disableDownload={isMeteoStation} />
      {isLoading ? (
        <CircularProgress
          size={25}
          color='white'
        />
      ) : (
        <IconButton
          disabled={isMeteoStation}
          //sx={{ display: { xss: 'none', lg: 'block' } }}
          onClick={handlePrint}
        >
          <PrintIcon
            htmlColor='#fff'
            fontSize='medium'
          />
        </IconButton>
      )}
    </Box>
  )
}

export default ActionMenu
