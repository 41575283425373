import { notifySuccess } from '@/components'
import IconsSelector from '@/components/Molecules/IconsSelector/Icons'
import { useActions, useAppSelector } from '@/hooks'
import {
  useGetDeviceDetailQuery,
  useGetPhotosDetailQuery,
  useUpdateTerminalCommandsMutation,
} from '@/modules/device-module/services/deviceService'
import { stateDeviceSelector } from '@/modules/device-module/store/stateSlice'
import { terminalCommandsSelector } from '@/modules/device-module/store/terminalNewSlice'
import { getDevicesIds } from '@/modules/device-module/utils'
import { useGetUserProfileQuery } from '@/modules/user/services/userService'
import { UserTypes } from '@/types/typeUser'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EditIcon from '@mui/icons-material/Edit'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import {
  Badge,
  Box,
  Checkbox,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material'
import * as React from 'react'
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Calendar from './ui/Calendar'
import FilterByModule from './FilterByModule'
const ActionsIcons = () => {
  const { id } = useParams()
  const { pathname } = useLocation()

  const { data: currentUserData } = useGetUserProfileQuery('0')
  const currentUserType = currentUserData?.data.typeId

  const {
    toggleOnChangeDevice,
    triggerLoadHistory,
    setSelected,
    setFilters,
    setSelectedDevice,
  } = useActions()
  const { isOnChange, device, selected, selectedFilters, selectedTab } =
    useAppSelector(stateDeviceSelector)

  const { data } = useGetDeviceDetailQuery(Number(id))
  const { data: photosData } = useGetPhotosDetailQuery(Number(id), {
    skip: !pathname.includes('photos'),
  })
  // const { refetch } = useGetTerminalCommandsQuery(Number(id), {
  //   skip: currentUserType !== UserTypes.superAdmin || selectedTab !== 5,
  // })
  const commands = useAppSelector(terminalCommandsSelector)
  const [updateTerminalCommandsService] = useUpdateTerminalCommandsMutation()

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && device) {
      const newSelected = device.modules.map((n) => n.id)
      setSelected(newSelected)
      setSelectedDevice(true)
      return
    }
    setSelected([])
    setSelectedDevice(false)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e: any) => {
    if (
      e.target.nodeName === 'LI' ||
      e.target.nodeName === 'INPUT' ||
      e.target.ariaLabel === 'menu'
    ) {
      return
    }
    setAnchorEl(null)
  }

  const onSelectedFilter = (id: number) => {
    if (id === 0) {
      setFilters([0])
    } else {
      const filtersIds = getDevicesIds(id, selectedFilters)
      const indexZero = selectedFilters.indexOf(0)
      if (indexZero !== -1) {
        filtersIds.splice(indexZero, 1)
      }
      setFilters(filtersIds)
    }
  }

  const filters = [
    { id: 0, name: 'Выбрать все' },
    { id: 1, name: 'Исправны' },
    { id: 2, name: 'Ошибки в работе' },
    { id: 3, name: 'Неисправны' },
  ]

  const filtersEvents = [
    { id: 0, name: 'Выбрать все' },
    { id: 1, name: 'Отправка команд' },
    { id: 2, name: 'Ошибки в работе' },
    { id: 3, name: 'Критические ошибки' },
    { id: 4, name: 'Информационные сообщения' },
  ]

  const onSaveCommands = async () => {
    try {
      const res = await updateTerminalCommandsService({
        commands,
        deviceId: Number(id),
      }).unwrap()
      triggerLoadHistory()
      if (res.success) {
        notifySuccess('Команды сохранены')
        toggleOnChangeDevice()
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (isOnChange) {
      toggleOnChangeDevice()
    }
  }, [id])

  const showFilterByModule =
    selectedTab === 2 && data?.typeId !== 8 && photosData?.typeId !== 7

  return (
    <Box
      display='flex'
      flexWrap={{ xss: 'wrap', sm: 'nowrap' }}
      justifyContent={{ xss: 'center', sm: 'unset' }}
    >
      {selectedTab === 5 ? (
        <Box
          display='flex'
          gap={2}
          alignItems='center'
        >
          {isOnChange ? (
            <>
              <IconButton
                sx={{ ml: 2 }}
                onClick={onSaveCommands}
              >
                <CheckCircleIcon
                  htmlColor={'green'}
                  fontSize='large'
                />
              </IconButton>
              <IconButton
                sx={{ ml: 0.5 }}
                onClick={() => {
                  toggleOnChangeDevice()
                }}
              >
                <CancelIcon
                  htmlColor={'#0072BC'}
                  fontSize='large'
                />
              </IconButton>
            </>
          ) : (
            <IconButton
              sx={{ ml: 2 }}
              onClick={() => toggleOnChangeDevice()}
            >
              <EditIcon
                htmlColor={'dark-gray'}
                fontSize='medium'
              />
            </IconButton>
          )}
          {data?.connectionStatus === 2 || data?.connectionStatus === 3 ? (
            <span
              style={{
                fontSize: 18,
                fontStyle: 'italic',
                color: '#BABAC0',
                paddingTop: 4,
              }}
            >
              Устройство не в сети
            </span>
          ) : null}
        </Box>
      ) : null}
      {selectedTab === 0 ? (
        <>
          {data?.typeId === 7 && <Calendar />}
          {data?.typeId === 7 ||
          data?.typeId === 8 ||
          photosData?.typeId === 7 ? null : (
            <>
              {data?.typeId === 4 ? null : (
                <Checkbox
                  sx={{ marginLeft: 0.5 }}
                  indeterminate={
                    selected?.length > 0 &&
                    selected?.length < (device ? device?.modules?.length : 0)
                  }
                  checked={
                    (device ? device?.modules?.length : 0) > 0 &&
                    selected?.length === (device ? device?.modules?.length : 0)
                  }
                  onChange={handleSelectAllClick}
                />
              )}
              {currentUserType === UserTypes.user ? null : (
                <IconButton
                  disabled={isOnChange}
                  onClick={() => toggleOnChangeDevice()}
                >
                  <EditIcon
                    htmlColor={isOnChange ? '#5ebc69' : '#0072BC'}
                    fontSize='medium'
                  />
                </IconButton>
              )}
            </>
          )}
        </>
      ) : null}
      {(selectedTab === 0 &&
        data?.typeId !== 7 &&
        data?.typeId !== 8 &&
        photosData?.typeId !== 7) ||
      selectedTab === 2 ? (
        <>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{ maxHeight: 450 }}
          >
            <MenuList>
              {(selectedTab === 0 ? filters : filtersEvents)?.map(
                (item, index) => {
                  let iconId = item.id
                  if (item.name === 'Отправка команд') {
                    iconId = 101
                  }
                  return (
                    <MenuItem
                      key={index}
                      id={item?.id?.toString()}
                      onClick={handleClose}
                      onClickCapture={() => {
                        onSelectedFilter(item.id)
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedFilters.includes(item.id)}
                          onChange={(e) => {
                            e.stopPropagation()
                            onSelectedFilter(item.id)
                          }}
                        />
                      </ListItemIcon>
                      <Box
                        display='flex'
                        alignItems='center'
                        gap={1}
                        aria-label='menu'
                      >
                        <IconsSelector id={iconId} />
                        {item.name}
                      </Box>
                    </MenuItem>
                  )
                }
              )}
            </MenuList>
          </Menu>
          <IconButton onClick={handleClick}>
            <Badge
              color={
                selectedFilters?.length > 0 && !selectedFilters.includes(0)
                  ? 'green'
                  : 'transparent'
              }
              variant='dot'
            >
              <FilterAltIcon
                htmlColor={'dark-gray'}
                fontSize='medium'
              />
            </Badge>
          </IconButton>
          {showFilterByModule && <FilterByModule />}
          <Calendar />
        </>
      ) : null}
    </Box>
  )
}

export default ActionsIcons
